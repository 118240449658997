import PropTypes from 'prop-types';
import Quote from 'components/contentBlocks/Quote';
import React from 'react';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

// Pure CMS data transformation before we send it to the "real" block!
const QuoteBlock = ({ content = {}, isColumn = false, moduleTextColor = '' }) => {
    const { author, quote } = content;

    return <Quote author={author} fullWidth={!isColumn} textColor={moduleTextColor} quote={quote} />;
};

QuoteBlock.propTypes = {
    content: PropTypes.shape({
        author: PropTypes.string,
        quote: wysiwygProp,
    }).isRequired,
    isColumn: PropTypes.bool,
    moduleTextColor: PropTypes.string,
};

export default QuoteBlock;
