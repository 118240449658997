import AnimationWrapper from 'components/wrappers/AnimationWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div', { shouldForwardProp: prop => ['fullWidth'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ${({ fullWidth }) =>
        fullWidth &&
        `
        ${above.md}{
            max-width: 66.66%;
            justify-content: center;
        }
    `}
`;

const TextWrapper = styled(AnimationWrapper)`
    margin-top: 40px;
`;

const Name = styled(Tag, { shouldForwardProp: prop => ['beforeColor'].indexOf(prop) === -1 })`
    display: inline-flex;
    align-items: center;

    &::before {
        content: '';
        width: 24px;
        height: 1.5px;
        margin-right: 8px;
        background-color: ${({ beforeColor }) => beforeColor};
    }
`;

/**
 * Display a quote with the authors name beneath.
 *
 * @param {string} author - Name of the author.
 * @param {bool} fullWidth - Adjusts the appearance for full screen.
 * @param {array} quote - wysiwyg displayed with quote styling.
 * @param {string} textColor - Color of all text.
 */

const Quote = ({ author, fullWidth = true, quote, textColor = '' }) => {
    const { t } = useTranslation();

    return (
        <Wrapper color={textColor} fullWidth={fullWidth}>
            <div>
                <Tag withAnimation>{t('modules.quote')}</Tag>
                <TextWrapper>
                    <Wysiwyg
                        data={quote}
                        tagComponents={{ url: TextLinkWysisyg }}
                        textComponent={props => <Paragraph size="xl" {...props} />}
                    />
                </TextWrapper>
            </div>
            <Name withAnimation beforeColor={textColor} marginTop={['80px', null, '120px']}>
                {author}
            </Name>
        </Wrapper>
    );
};

Quote.propTypes = {
    author: PropTypes.string,
    fullWidth: PropTypes.bool,
    quote: wysiwygProp,
    textColor: PropTypes.string,
};

export default Quote;
