import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import QuoteBlock from 'libs/wordpress/content/cmsBlocks/QuoteBlock';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import moduleMargins from 'config/theme/moduleMargins';
import { moduleSettingsProp } from 'utils/proptypes/modules/settingsProps';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const QuoteBlockWrapper = styled('div', { shouldForwardProp: prop => ['columnStyles'].indexOf(prop) === -1 })``;

const QuoteModule = ({ content, last = false, settings = {} }) => {
    const { background_color: backgroundColor, text_color: textColor } = settings;
    const {
        background_media_flexible: backgroundFlexible,
        settings: blockSettings,
        content: { content_alignment: contentAlignment },
    } = content;

    const activeImage = activeSetting(blockSettings, 'image');
    const contentLeft = contentAlignment === 'left';

    // Tranform Background Data
    const isAboveMd = useAboveBreakpoint('md', activeImage);
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const mobileBackground = transformedBackground.mobile;
    const desktopBackground = transformedBackground.desktop;

    return (
        <MaxWidthWrapper
            includeContentMargins
            as="section"
            backgroundColor={backgroundColor}
            borderBottom={!last ? `1px solid ${colors.black}` : null}
            py={moduleMargins.md}
            paddingTop={moduleMargins.md}
            paddingBottom={['24px', null, '32px', '24px']}
        >
            {!activeImage ? (
                <QuoteBlock content={content} moduleTextColor={textColor} />
            ) : (
                <ColumnsLayout
                    blocks={[
                        <AspectWrapper
                            key="image"
                            order={contentLeft ? [1, null, null, 2] : 1}
                            ratio={[ratios.vertical, ratios.square, ratios.verticalWide]}
                            size={[12, null, null, 6]}
                        >
                            <BackgroundSwitch
                                background={isAboveMd ? desktopBackground : mobileBackground}
                                type={isAboveMd ? desktopBackground.type : mobileBackground.type}
                                mediaQuery={mediaQueries.twoColumn}
                            />
                        </AspectWrapper>,
                        <QuoteBlockWrapper
                            columnStyles={{ mr: 'auto' }}
                            height={[null, null, null, '100%']}
                            key="quote"
                            order={contentLeft ? [2, null, null, 1] : 2}
                            size={[12, null, null, 5]}
                        >
                            <QuoteBlock isColumn content={content} moduleTextColor={textColor} />
                        </QuoteBlockWrapper>,
                    ]}
                />
            )}
        </MaxWidthWrapper>
    );
};

QuoteModule.propTypes = {
    content: PropTypes.shape({
        content: PropTypes.shape({
            'content_alignment': PropTypes.oneOf(['left', 'right']),
        }),
        'background_media_flexible': PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }),
        settings: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    last: PropTypes.bool,
    settings: moduleSettingsProp,
};

export default QuoteModule;
